.withdrawals {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
}

.withdrawals>form {
        width: 60%;

}

.withdrawals>form>button {
        width: 40%;
        margin: auto;
}

.no-investments {
        width: 100%;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        text-align: center;
       

        justify-content: center;
}
.percentagemessage{
        margin: auto;
        padding: 30px;
        background-color: white;
        width:60%;
}

@media screen and (max-width:640px) {
        .withdrawals {
                display: block;
                width: 100%;
        }

        .withdrawals>form {
                width: 80%;
                margin: auto;
        }

        .no-investments {
                padding: 0;
        }

        .no-investments>div>h1 {
                font-size: 16px;
        }
        .percentagemessage{
              
                width:90%;
        }
}