.Footer{
    width: 100%;

    color:var(--fontColor1);
    background-color:var(--background1);
}
 
.footer-container{
   
   width: 80%;
    margin: auto;
    
}

.layer1{
    display: flex;
    width: 100%;
   
}

.layer1>div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    padding: 1rem;
}

.layer1>div>h3{
   font-size: 1.5rem;
   margin-bottom: 1rem;
}

.footer-links{
    display: flex;
    flex-direction: column;
    gap:0.8rem;
  
}
.socials{
    display: flex;
    gap:1.2rem;
    font-size: 2.5rem;
}

.socials:hover{
    color:var(--orange);
}

.layer2, .layer3{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.layer2>p, .layer3>p{
    font-weight: bold;

}
.layer3{
    gap:2rem;
    padding: 1rem;
}

.risk-warning{
    font-size: 0.7rem;
    text-align: center;
   
}

.newsletterform>div>input{
    border: 1px solid var(--fontColor1);
    color: var(--fontColor2);
}

@media screen and (max-width:640px) {
   
    .layer1{
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        text-align: center;
        margin: auto;

    }
    .layer1>div{
        width: 100%;
    }
    .layer1>div>ul{
        width: 100%;
    }
    .newsletterform{
        display: none;
    }
    .layer1-right>h3{
        display: none;
       }
   

    h4{
        font-size: 1rem;
    }
    .risk-warning{
       width: 100%;
       padding: 30px;
    }
}