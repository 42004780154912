.brokers {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    /* background-color: var(--fontColor1); */
    background-color: white;
}



.broker-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 75vh;
    background-color: white;
    animation: slideUp 2s ease-in-out forwards, fadeIn 2s ease-in-out forwards;
    /* background: linear-gradient(to bottom, var(--backgroundColor), var(--backgroundColor2)); */
    background-color: white;


}


.broker-hero>h1 {
    font-size: 2rem;
    font-weight: 800;
    color: var(--fontColor1);
    z-index: 2;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;

}

.broker-hero>p {
    color: orangered;
    z-index: 2;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.broker-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 3rem 0;
    animation: slideUp 2s ease-in-out forwards, fadeIn 2s ease-in-out forwards;
    /* background: linear-gradient(to bottom, var(--backgroundColor2), var(--backgroundColor)); */
    background-color: white;
}

.broker-list>div {
    width: calc(100%/4);
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: var(--boxShadow);
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.sect {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    background: #b9c0cf;
    padding-bottom: 2rem;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
    /* background: linear-gradient(to bottom, var(--backgroundColor), var(--fontColor1)); */
    background-color: white;
}

.services {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;


}

.services>h2 {
    font-weight: 700;
    font-size: 1.8rem;
    margin: 2rem 0;
    color: var(--fontColor2);

}

.service-cards {
    display: flex;
    gap: 2rem;

}

.service-cards>div {
    width: 150px;
    height: 150px;
    box-shadow: var(--boxShadow);
    border-radius: 15px;
}

.service-cards>div>img {
    width: 100%;
    height: 100%;
    ;
    border-radius: 15px;
}


@media screen and (max-width:640px) {
    .broker-hero {
        padding: 25px;
        background-position: center;
        background-size: cover;
        height: 50vh;
    }

    .broker-hero::before {
        height: 50vh;
    }

    .broker-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .broker-list>div {
        width: 75%;
        padding: 2rem;
    }

    .service-cards {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .service-cards>div {
        width: calc(100% /2.5);
        height: 150px;
        box-shadow: var(--boxShadow);

    }

}