@keyframes hero {
    0%   {color: red;}
  25%  {color: yellow;}
  50%  {color: blue;}
  100% {color: green;}
  }

  @keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); /* Adjust bounce height */
    }
}
  @keyframes slideUp {
    0% {
        top: 50px; /* Starting position, adjust as needed */
        opacity: 0;
    }
    100% {
        top: 0; /* Final position, element slides up to the top */
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1; /* Fade in completely */
    }
}

@keyframes slideFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}