.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}

.container>div{
    width: 10%;
    
}