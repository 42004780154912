.passwordReset>p{
    font-size: 16px;
}

@media screen and (max-width:640px) {
    .passwordReset{
        width: 100%;
    }
}

