.support{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 0;
    /* background: linear-gradient(to bottom,  var(--fontColor2), var(--fontColor1)); */
    background-color: white;
}

.support>h1{
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
    color: var(--backgroundColor);
    animation: fadeIn 2s ease-in-out forwards, slideUp;
  
    
}
.support>ul>li{
    font-size: 18px;
    font-weight: 600;
    color: var(--backgroundColor);
    animation: fadeIn 3s ease-in-out forwards, slideUp;
}
.support>ul>li>b{
    font-size: 18px;
    font-weight: 600;
    color: orangered;
    text-shadow: var(--textShadow);
}

.support>h2{
    font-size: 1.5rem;
    font-weight: 800;
    margin: 2rem 0;
    color: var(--backgroundColor);
    animation: fadeIn 2s ease-in-out forwards, slideUp;
}

.contact-form{
    max-width: 450px;
}


@media screen and (max-width:640px) {
    .contact-form{
        width: 80%;
    }
  }
 
  
 
  
  
  
  
  
 
 
  /* Optional: Add media queries for responsiveness */
  @media (max-width: 768px) {
    .contact-form {
      max-width: 100%;
    }
  }
  