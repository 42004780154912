.optionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
}

.optionCard>.icon-image>img {
    width: 100%;
    padding: 20%;
    padding-bottom: 10px;
}

.optionCard>p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--fontColor1);
}