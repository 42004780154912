.Home-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(to right, rgb(21,84,92) 10%, rgb(22,31,53) 70%, rgb(11,14,22) 10%); */
    /* background: linear-gradient(to right, rgb(21, 84, 92) 5%, rgb(22, 31, 53) 70%, rgb(11, 14, 22) 90%); */
    background-color: black;
}


.section1-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;


}



.hero-section {
    width: 80%;
    ;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    position: relative;
    padding-bottom: 1rem;
    animation: slideUp 4s;

}

.hero-section>h1 {
    text-shadow: 2px 2px 4px #000000;

    /* //animation: name duration timing-function delay iteration-count direction fill-mode; */
    animation-name: hero, slideUp;
    animation-duration: 20s, 3s;
    animation-delay: 2s, 2s;
    /* Set specific iteration counts */
    animation-iteration-count: infinite, 2;
    /* First animation repeats infinitely, second animation repeats 2 times */


}

.section-description {
    color: white;
    font-size: 30px;
    padding-top: 10px;
    text-align: center;

}

.section-description2 {
    color: white;
    font-size: 20px;
    padding-bottom: 15px;
}


.hero-section>img {
    width: 70%;
    background: transparent;
    animation: bounce 3s infinite, slideUp 4s;

}

.hero-button {
    padding: 20px;
    width: 300px;
    font-size: 20px;
    color: white;
    transition: transform 0.3s;
}

.hero-button:hover {
    transform: scale(1.2);
}

.hero-buy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
    color: white;
    animation: slideUp 6s;
}


.section3-contents>h1 {
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
    padding: 15px;
    text-shadow: 2px 2px 4px #000000;
}

.site-description {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
    gap: 1rem;
    padding-bottom: 1rem;
}

.site-description-left {
    width: 50%;
    padding: 3rem;

}

.site-description-left>img {
    width: 90%;
    animation: slideUp 4s ease-in-out forwards 1 2s, bounce 6s infinite;

}

.site-description-right {
    width: 50%;
    padding: 1rem;
}

.site-description-right>p {
    font-size: 17px;
    font-weight: 400;
    line-height: 2.5;
    color: var(--fontColor2);
    opacity: 0;
    /* Start with an invisible paragraph */
    animation: fadeIn 2s ease-in-out forwards, slideUp;
}

.section3-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}

.section3-contents {
    width: 80%;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 1rem;
    animation: fadeIn 2s ease-in-out forwards, slideUp;
}


.whychooseus {
    display: flex;
    animation: fadeIn 3s ease-in-out forwards, slideUp;
}

.whychooseus>div {
    width: calc(100% /5);
}

.section3-contents>button {
    background-color: var(--backgroundColor);
}

.section-4 {
    display: flex;
    flex-direction: column;

}

.section-4-contents {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;

}

.section6-container,
.section7-container,
.section8-container {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
    animation: slideUp 2s ease-in-out forwards, fadeIn 2s ease-in-out forwards;
}

.section6-contents,
.section7-contents,
.section8-contents {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.section6-container {}

.section6-contents>h1 {
    text-shadow: 2px 2px 4px #000000;
}

.pricing-container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.pricing-container>.left {
    animation: slideFromLeft 1s ease-in-out;

}

.pricing-container>.right {
    animation: slideFromRight 1s ease-in-out;
}

.pricing-container>div {
    display: flex;
    width: 40%;
    flex-direction: column;
    gap: 10pxrem;
    text-align: center;
    background-color: whitesmoke;
    border-radius: 15px;
    padding: 1rem 0;
    box-shadow: var(--boxShadow);

}

.pricing-container>div>h1,
.pricing-container>div>h2,
.pricing-container>div>h3 {
    color: orangered;
    text-shadow: 2px 2px 4px #000000;
}

.pricing-container>div>ul {
    text-align: left;
    margin: auto;
    width: 70%;
    list-style-type: square;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 20px;
    font-size: 18px;
    color: var(--backgroundColor);
}


.section7-container {}

.section7-contents>.icon {
    font-size: 6rem;
    color: aquamarine;
}

.section7-contents>h1 {
    color: var(--fontColor1);
}

.section7-contents>button>a {
    color: var(--backgroundColor2);
    display: block;

    padding: 10px;
}

.section7-contents>button {
    color: var(--fontColor1);
    display: block;

    padding: 0;
}



.section8-container {}

.custom-details-group {
    margin: 20px;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    /* background-color: #f0f0f0; */
    width: 100%;
    display: flex;
    flex-direction: column;

}

/* Style the details container */
details {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    color: var(--backgroundColor2);


    margin-bottom: 10px;

}

/* Style the summary element */
summary {
    background-color: var(--fontColor1);
    color: var(--backgroundColor2);
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: var(--boxShadow);
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

/* Style the summary when it's open
  summary::-webkit-details-marker {
    display: none;
  } */
summary::marker {
    float: right;
}

/* Style the content within the details element */
details p {
    margin: 0;
    padding: 10px;
    background-color: transparent;
    font-size: 16px;
    color: var(--fontColor2);
}


.section5-container {
    width: 80%;
    margin: auto;
    color: white;
}

.section5-container>div>ul {
    font-size: 30px;
    padding-left: 300px;
}

.section7 {
    padding-top: 60px;
}

.section7-container {
    border: 3px solid rgb(22, 130, 221);
    border-radius: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:20px;
    text-align: center;
    color: white;
    width: 60%;
    padding: 40px;
}

.section7-container>div>img {
    width: 40%;
}

@media screen and (max-width:640px) {
    .Home-div {

        /* background: linear-gradient(to right, rgb(21, 84, 92) 2%, rgb(22, 49, 75) 99%); */
    }

    .section1-container,
    .section3-container,
    .section4-container,
    .section5-container,
    .section6-container,
    .section7-container,
    .section8-container,
    .section3-contents,
    .section4-contents,
    .section5-contents,
    .section6-contents,
    .section7-contents,
    .section8-contents {
        width: 100%;
    }

    .hero-section {
        padding: 1rem;
        display: flex;

    }

    .hero-section>h1 {
        font-size: 3rem;
        font-weight: 800;
        margin: 0;
        margin-bottom: 1rem;
    }

    .section-description {
        font-size: 25px;

    }

    .hero-section>img {
        width: 100%;


    }

    .section3-contents>h1 {
        font-size: 28px;
    }

    .site-description {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .site-description>div {
        width: 100%;
        text-align: center;
    }

    .site-description-left {
        padding: 3rem;
    }

    .site-description-left>img {
        width: 60%;
    }

    .site-description>div>p {
        padding: 0 1rem;

    }

    .whychooseus {
        flex-direction: column;
        align-items: center;
        padding-bottom: 1rem;
    }

    .whychooseus>div {
        width: 60%;
    }

    .section-4 {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px;

    }



    .pricing-container {
        flex-direction: column;
        padding: 0px;
        width: 100%;
    }

    .pricing-container>div {
        width: 70%;
        padding: 1rem 0;
        gap: 0px;

    }

    /* mobile */
    .pricing-container>div>h1,
    .pricing-container>div>h2,
    .pricing-container>div>h3 {
        color: orangered;
        text-shadow: 2px 2px 4px #000000;

    }

    .pricing-container>div>ul {
        text-align: left;
        margin: auto;
        width: 70%;
        list-style-type: square;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 10px;
        font-size: 16px;
        color: var(--backgroundColor);
    }

    .section-header {
        text-align: center;
        font-size: 20px;
    }

    .section5-container {
        width: 80%;
    }

    .section5-container>div>ul {
        font-size: 20px;
        padding-left: 100px;
    }
    .section7-container{
        width: 80%;
    }
}