/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    

}

.nav-container {
    width: 90%;
    display: flex;
    align-items: center;
    ;
    justify-content: space-between;
    
}

.logo {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 900;
    text-shadow: 2px 2px 4px #000000;
    color: var(--fontColor1);
}

.logo>span {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 900;
    color:orangered
}

.nav-container>ul {
    list-style-type: none;
    
    font-size: 16px;
    font-weight: 400;
   
    letter-spacing: 0em;
    text-align: left;
    color: white;
    text-decoration: none;
 

}

.nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.other-nav-menu{
    display: flex;
    gap:10px;
}





.menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
 
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    

    .nav-container>ul>li>a{
        color: white;
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -1px;
        text-align: left;

    }

    .nav-links {
        display: none;
    }


    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        gap:1.3rem;
        top: 82px;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 30px;
        color: white;
        background: var(--backgroundColor);
        z-index: 999999999999999;
    }

    .nav-button {
        margin-left: 0px;
    }

    .menu-icon {
        display: block;
    }
}