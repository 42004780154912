.account-page {
    display: flex;
    justify-content: center;

    width: 100%;
  
    background: linear-gradient(to bottom, var(--fontColor2), var(--fontColor1));
    /* background-color: white; */

 
}

.accountpage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    gap: 1rem;
    width: 40%; 
 
    padding: 0 40px;
}

@media screen and (max-width:640px) {
    .account-page {
        display: block;
        width: 100%;
        padding: 1rem 0;
     
    }
    .accountpage-container {
       display: flex;
       justify-content: center;
     
       
       width: 80%;
       
       padding: 0;
       margin: auto;
     
    }
}