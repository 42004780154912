.formContainer {
    width: 60%;
    margin: auto;
}


@media screen and (max-width:640px) {
    .formContainer {
        width: 100%;
    }
    .formContainer>h1 {
        font-size: 16px;
    }
}