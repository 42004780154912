.payment-container {
    display: flex;
    width:100%;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.pay-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
}

.payment-params{
    display: flex;
    flex-direction: column;
    gap:1rem;
}

.payOPT {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap:2rem;
    text-align: center;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3);
    padding: 2rem;
    border-radius: 10px;
    background-color: white;
    
}

.payOPT:hover{
    color: var(--backgroundColor);
    background-color: var(--backgroundColor);
}


.payOPT>h2 {
    font-size: 1rem;
    color: chocolate;
}

.payicon {
    font-size: 3rem;
    color: chocolate;
}

.paymentbox{
    box-shadow: 0px 5px 15px rgb(0 0 0 / 0.3);
}

.loadingpage{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 3rem;
    
    
}

.paydetail{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: center;
   
}

.payOptNotSelected{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    gap:2rem;
}


.pay-controls{
    display: flex;
    justify-content: space-between;
    gap:5px;
    margin-top: 1rem;
}

.pay-controls>button{
    font-size: 12px;
    width: 100px;
}

.uploadcontrols{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}
.crypto-addres-detail{
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    border: 2px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.crypto-addres-detail>h4{
    font-size: 12px;
    font-weight: 400;
}
.crypto-addres-detail>*:hover{
    color:orangered;
    text-shadow: var(--textShadow);
}
.uploadbutton{
    font-size: 12px;
    font-weight: 800;
    width: 120px;
    padding: 10px;
}


@media screen and (max-width:640px) {
    .payment-container {
        width:100%;
        gap: 4rem;
        align-items: center;
    }

    .pay-select {
        width: 60%;
        flex-direction: row;
        gap:1rem;
    }

    .payOPT {
        
        width: 100px;
        height: 100px;
        gap: 0rem;
       padding: 1rem;
        
        
    }

    .payOPT>h2 {
        font-size: 0.7rem;
        text-align: center;
        font-weight: 800;
        color: chocolate;
    }

    .payicon {
        font-size: 1.5rem;
       
    }
    .crypto-addres-detail{
       
       
        width: 80%;
    }
   
  }