.cart{
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    padding:20px;
  
   
}

.cart>h1{
    text-transform: capitalize;
    margin: 1rem 0;
}
.cart-items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.cart-item-container{
    display: flex;
    flex-direction: column;
    gap:1rem;
}