.autopilotpro {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* background-color: var(--fontColor1); */
  background-color: white;
  padding: 2rem 0;
}

.L1 {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  gap: 1rem;
  /* background: linear-gradient(to bottom, var(--fontColor1), var(--backgroundColor)); */
  background-color: white;
}

.L1-left {
  width: 70%;

  animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;

}

.L1-left>img {
  width: 100%;
  border-radius: 15px;

  animation: bounce 3s infinite, slideUp 4s;
}


.L1-right {
  width: 30%;
  padding: 2rem;
  background: lightgray;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 15px;
  animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.L1-right>h3 {
  background-color: darkred;
  padding: 5px;
  text-align: center;
  color: white;
  font-size: 0.8rem;
}

.L1-right>p {
  text-decoration: line-through;
  margin-bottom: 0;
}

.L1-right>h2 {
  font-weight: bold;
  font-size: 1.5rem;
}

.L1-right>button {
  padding: 10px;
}

.L2 {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background: linear-gradient(to bottom, var(--backgroundColor), var(--backgroundColor2)); */
  background-color: white;
}

.L2>h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: var(--textShadow);
  color: var(--fontColor1);
  animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.L2>p {
  font-weight: bold;
  color: var(--fontColor2);
  text-shadow: var(--textShadow);
  animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.description {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
  color: orangered;
}

.L3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/fximage.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 120vh;
  position: relative;

}

.L3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.8);
  /* Semi-transparent color */
  z-index: 1;
}

.L3>h1 {
  z-index: 2;
  color: aliceblue;

  padding: 2px;
  font-size: 2.8rem;
  font-weight: 800;
  text-align: center;
  /* //animation: name duration timing-function delay iteration-count direction fill-mode; */
  animation-name: hero, slideUp;
  animation-duration: 20s, 3s;
  animation-delay: 2s, 2s;
  /* Set specific iteration counts */
  animation-iteration-count: infinite, 2;
  /* First animation repeats infinitely, second animation repeats 2 times */

}

.L4,
.L5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  text-align: center;
  width: 100%;
}

.L4 {
  /* background: linear-gradient(to bottom, var(--backgroundColor2), var(--backgroundColor)); */
  background-color: white;
}


.L4>button {
  width: 150px;
  margin-bottom: 2rem;

}

.L4>button>a {
  color: var(--backgroundColor2);
  display: block;

}

.L4>h1 {
  font-size: 1.5rem;
  font-weight: 800;
  margin-bottom: 2rem;
  color: var(--fontColor1);
}

.L4>p {
  width: 90%;
  font-size: 16px;
  color: orangered;
  text-shadow: var(--textShadow);
}

.L5 {

  font-size: 16px;
  color: orangered;
  text-shadow: var(--textShadow);
}



@media screen and (max-width:640px) {

  .L1 {
    flex-direction: column;
  }

  .L1-left,
  .L1-right {
    width: 100%;

  }

  .L3 {
    background-size: cover;
    width: 100%;
    height: 60vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .L3>h1 {
    padding: 1px;
    font-size: 2rem;
    font-weight: 800;
    width: 50%;
  }

  .L3::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Semi-transparent color */
    z-index: 1;
  }
}