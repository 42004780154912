.form-container{
    width: 60%;
    padding: 1rem;
    margin: auto;
}

.admin-login-container{
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:2rem;
}