.broker-card{
    display: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1.2rem;

}

.broker-card>img{
    width: 100%;
    height: 100px;
    border: 15px;
}
.broker-card-details{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.broker-card-details>button>a{
  color: var(--backgroudColor);
  display: block;
  
}

@media screen and (max-width:640px) {
    .broker-card>img{
        width: 70%;
        height: 100px;
        border: 15px;;
    }
    
}