.cart-item{
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: var(--boxShadow);
    border-radius: 15px;
   
}

.cart-item-left{
    width: 75%;
    display: flex;
    gap:1rem;
    
}
.product-image-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-item-count{
    border-radius: 100%;
    background: grey;
    width: 20px;
    height: 20px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--boxShadow);
    color: white;
    font-size: 0.7rem;
    text-shadow: var(--boxShadow);
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -10px;
    margin-right: -10px;
}

.product-image-container>img{
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid gray;
        padding: 10px;
        object-fit: cover;

}
.cart-detail-container{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
}
.cart-detail-container>p{
       font-size: 0.8rem;
       font-weight: 800;
}


.cart-item-right{
    text-align: center;
    font-size: 0.9rem;
    width: 25%;
    display: block;
}