.allUSerDataContainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding:0.5rem
}


.userAcount>h3{
    color:blueviolet;
}

.userAccountDetails{
    display: flex;
    gap:1rem;
    padding: 1.2rem;
}
.userAccountDetails>div{
    width: calc(100% / 3);  
    box-shadow: var(--boxShadow);
    padding:1rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.userAccountDetails>div>*{
    font-size: 12px;
}
.userAccountDetails>div>h1{
   padding-bottom: 10px; 
}
.userAccountDetails>div>p>b{
    font-size: 12px;
}

.action-display-button{
    font-size: 12px;
}