.dash-board-card-container{
    display: flex;
    width: 100%;
    display: flex;
    
    gap: 2rem;

 
}


.dash-board-card{
   
    width: calc(100% / 4);
    box-shadow: var(--boxShadow);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
    border-radius: 15px;
    text-align: center;
    color:var(--fontColor1);
    background-color: var(--backgroundColor);
    animation: slideUp 2s ease-in-out forwards, fadeIn 3s ease-in-out forwards;
}

.icn{
    font-size: 2rem;
}

.dash-board-card>h3{
    font-weight: 500;
    font-size: 15px;
}
.dash-board-card>h1{
    font-weight: 800;
    font-size: 1.5rem;
    color: var(--fontColor2);
}



@media screen and (max-width:640px) {
    .dash-board-card-container{
        flex-wrap: wrap;
        width: 100%;
        
        gap:1rem;
        justify-content: center;
    }

    .dash-board-card{
        width: calc(100% / 2.2);
        
       
    }
}