.login-form{
    margin: auto;
    width: 100%;
}
.login-form>form{
  
}


@media screen and (max-width:640px) {
    .form{
        width: 100%;
    }
  }
