.comp {
    border: 3px solid rgb(22, 130, 221);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px;

}

.comp-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    color: white;
    gap: 10px;
}

.title-cont {
    display: flex;
    align-items: center;
    gap: 5px;
}

.title-cont>img {
    width: 25px;
    height: 25px;
}

.comp-left>div {
    text-align: left;
}

.comp-right {
    width: 50%;
}

.comp-right>img {
    width: 100%;
}

@media screen and (max-width:640px) {
    .comp {
        flex-direction: column-reverse;
        
    }

    .comp-left {
        width: 100%;
    }

    .comp-left>div>p {
        text-align: justify;
    }

    .comp-right {
        display: relative;
        width: 50%;

    }

    .comp-right>img {
        z-index: 10;
        margin-top: -150px;
        transform: scale(0.7);
    }
}