.checkout{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    color: var(--backgroundColor);
    background: linear-gradient(to bottom, var(--fontColor2), var(--fontColor1))
}

.check-out-container{
    
    width: 80%;
    display: flex;
    /* border: 2px solid black; */
    
}

.checkout-left{
    padding: 2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap:1rem;
    /* border: 2px solid black; */
    animation: slideUp 2s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}

.checkoutform-container{
    /* border: 2px solid red; */
    padding: 1rem;
}

.checkoutform-container>form{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    animation: slideUp 3s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}


.checkout-left>form>button{
   width:250px;
   margin: auto;
}

.checkout-left>form>input[type="text"],input[type="email"] , input[type="password"] {
    border-bottom: 1px solid grey;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}
.checkout-right{
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
    flex: 1;
}

.error{
    font-size: 10px;
    color: red;
    font-weight: 800;
    margin-top: -15px;
}

.checkout-payment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem;
    padding:2rem;
    animation: slideUp 4s ease-in-out forwards, fadeIn 4s ease-in-out forwards;
}
.checkout-payment>button{
    width: 50%;
}

@media screen and (max-width:640px) {
    
    .check-out-container{
        flex-direction: column-reverse;
        width: 100%;
        gap: 2rem;
    }

    .checkout-left{
        padding: 0;
        display: flex;
        flex-direction: column;
    }


    .checkoutform-container{
        padding: 0;
        
    }

    .checkoutform-container>form{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        /* padding: 1rem; */
    }

    
  }