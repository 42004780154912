@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

@import url('../src/styles/keyframes.css');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;


}

:root {
  --backgroundColor: #10102c;
  --backgroundColor2: #000008;
  --fontColor1: #4747be;
  --fontColor2: #8686ff;
  --textShadow: 2px 2px 4px #000000;
  --boxShadow: 0px 5px 15px rgb(0 0 0 / 0.3);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

a {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  display: block;
  color: #4747be;
  text-decoration: none;
  transition: font-size 0.3s, color 0.3s;
}

a.active {
  color: white;
}

a:hover {
  color: #8686ff;
  font-size: 16.5px;
  /* Change the font size when hovered */
}

body {
  background: var(--backgroundColor);
}

.siteButton {
  border: 0;
  padding: 10px;
  width: 150px;
  background-color: #4747be;
  border-radius: 2px;
  color: white;
}

.siteButton>a {

  color: white;
}

.siteButton:hover {
  background-color: var(--backgroundColor);
  color: white;
  transition: scale(2% 2%);
}

.section-header {
  font-size: 4rem;
  font-weight: 800;
  margin: 3rem 1rem 0 0;
  color: var(--fontColor2);
  text-align: center;
}


ul {
  list-style-type: none;
}

form {

  width: 100%;
  padding: 20px;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  gap: 2rem;
  margin-bottom: 2rem;
  animation: fadeIn 1s ease-in-out forwards, slideUp;
}

form div {
  margin-bottom: 15px;
  animation: fadeIn 1s ease-in-out forwards, slideUp;
}

form input[type="text"],
form input[type="email"],
form input[type="password"],
form input[type="number"],
form textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid var(--backgroundColor);
  border-radius: 5px;
  color: var(--backgroundColor2);
  background: transparent;
}

form button {
  background-color: var(--backgroundColor);
  color: var(--fontColor2);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: var(--fontColor2);
  color: var(--backgroundColor2);
}

.error {
  color: red;
  font-size: 12px;
}

form h2 {
  color: var(--backgroundColor)
}


select {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--backgroundColor);
  background-color: transparent;
  outline: none;

}

select>option {
  position: absolute;
  width: 100%;
  font-size: 15px;
  background-color: var(--backgroundColor);
  color: var(--fontColor1);

}

.account-header {
  padding: 1rem 0;
  font-size: 18px;
  animation: fadeIn 2s ease-in-out forwards, slideUp;
}

.account-nav {
  color: var(--backgroundColor);
  padding: 2px;
  text-align: center;
  font-size: 14px;
}

.account-nav:hover {
  color: white;
}




.icon {
  font-size: 2rem;
}

.icon:hover {
  color: var(--orange);

}

::placeholder {
  color: var(--backgroundColor2);
  /* Change the color to your desired color */
}

@media screen and (max-width:640px) {
  .form {
    width: 100%;
  }
}


table {
  animation: fadeIn 3s ease-in-out forwards, slideUp 2s ease-in;
}